<template>
  <section>
    <OwnerSidebar />
    <div class="dashboardContent">
      <router-view></router-view>
    </div>
  </section>
</template>

<script>
import OwnerSidebar from "../nav/OwnerSidebar.vue";
export default {
  components: { OwnerSidebar },
};
</script>

<style lang="scss" scoped>
.dashboardContent {
  position: relative;
  width: 100%;
  padding: 40px;
}

section {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}
</style>
